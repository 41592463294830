import classNames from 'classnames';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import Layout from '../components/layout';
import SEO from '../components/SEO';
import sectionConductor from '../sections/section-conductor';

class ServicesPage extends Component {
  render() {
    const { data, location, pageContext } = this.props;
    const { contentfulLayout: page } = data;

    const renderedSections = page.sections.map((section, i) => sectionConductor(section, i));

    const pageWrapClasses = classNames({
      page: true,
      [`page--${page.contentfulid}`]: true,
    });

    return (
      <div className={pageWrapClasses}>
        <Layout pathname={location.pathname}>
          <SEO title={page.title} metadata={page.metadata} routeName="services:list" />
          {renderedSections}
        </Layout>
      </div>
    );
  }
}

export default ServicesPage;

export const pageQuery = graphql`
  query ($contentfulid: String!, $whatRegion: String!) {
    contentfulLayout(contentfulid: { eq: $contentfulid }, whatRegion: { eq: $whatRegion }) {
      contentfulid
      title
      url
      metadata {
        ...Metadata
      }
      ...AllSectionsFragment
    }
  }
`;

ServicesPage.propTypes = {
  children: PropTypes.node,
  navbarIsInverted: PropTypes.bool,
};
